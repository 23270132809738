/**
 * Foursource page
 * https://foursource.com/references
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/references";
import { membersTexts } from "../content/pages/members";
import { associates } from "../content/pages/associatesPrincipal";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import Associates from "../components/_page/associates";
import MembersTabs from "../components/_page/_membersTabs";

const MOBILE_WIDTH = 992;

class referencesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: (typeof window !== "undefined") ? (window.innerWidth <= MOBILE_WIDTH ? true : false):'',
    };
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.membersTexts = membersTexts(this.t);
    this.associates = associates(this.t);
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MOBILE_WIDTH ? true : false;
    this.setState({
      mobile: show,
    });
  };


  render() {
    return (
      <>
        <Layout pageName="references" whiteMenu={false}>
          <TextImgBackgroundFull info={this.state.mobile ? this.text.topInfoMobile : this.text.topInfo}></TextImgBackgroundFull>
          <MembersTabs tabList={this.membersTexts.tabList} tabsPanel={this.membersTexts.tabContent}></MembersTabs>
          <div className="mt-50 pt-75 topDiagonal backgroundGrey">
            <Associates info={this.associates.cardsInfo}></Associates>
          </div>
        </Layout>
      </>
    );
  }
}
export default withTrans(referencesPage);
