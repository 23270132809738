// images
import TopBackground from "../images/ours-members-buyers.jpeg";
import TopBackgroundMobile from "../images/headersMobile/4-our-members-buyers-mobile.jpg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("references.topTitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: false,
      image: {
        src: TopBackground,
        alt: t("references.imgAlt"),
        smallHeight: false,
      },
      cta: {
        text: t("meta.demoRequestText"),
        url: t("meta.demoDeskLink"),
        type: "infoColor",
        extraClass: "bigWidth",
      },
    },
    topInfoMobile: {
      title: {
        text: t("references.topTitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: false,
      image: {
        src: TopBackgroundMobile,
        alt: t("references.imgAlt"),
        smallHeight: false,
      },
    },
  };
};
