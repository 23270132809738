import * as React from "react";

import "../../style/components/tabs.scss";

// react-tabs
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

// components
import TitleSeparator from "../titleSeparator";
import ImgLabel from "../imgLabel"; 
import Button from "../_partials/_button"; 

class MembersTabs extends React.Component {
  render() {
    const { tabList, tabsPanel } = this.props;

    return (
      <>
        <div className="membersTabs paddingSidesBig pt-50">
          <Tabs>
            <TabList className="m-0 p-0 tabs-separator">
              {tabList.map((tab, i) => (
                <Tab key={i}>{tab}</Tab>
              ))}
            </TabList>
            {tabsPanel.map((tabContent, i) => (
              <TabPanel key={i} className="text-center tab-content">
                <div className={tabContent.top.backgroundGrey + " p-20"}>
                  <TitleSeparator info={tabContent.top}></TitleSeparator>
                  <div className="pt-50 pt-sm-10 row row-cols-2 row-cols-xl-5 row-cols-lg-5 row-cols-md-4 row-cols-sm-3">
                    {tabContent.content.map((content, a) => (
                      <div key={a} className="p-3">
                        <ImgLabel info={content}></ImgLabel>
                      </div>
                    ))}
                  </div>
                  <div className="pt-20 pb-20">
                    <Button cta={tabContent.cta}></Button>
                  </div>
                </div>
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </>
    );
  }
}

export default MembersTabs;
