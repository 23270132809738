import Utm from "../../components/_global/utm";

// images 
import LogoCarrefour from "../../images/members/carrefour.jpg";
import LogoCentric from "../../images/members/centric-brands.jpg";
import LogoChargeurs from "../../images/members/chargeurs.jpg";
import LogoChelmsford from "../../images/members/chelmsford-safety-suppliers-ltd.jpg";
import LogoChildren from "../../images/members/children-worldwide-fashion.jpg";
import LogoCrew from "../../images/members/crew-clothing-company.jpg";
import LogoDoubleTwo from "../../images/members/double-two.jpg";
import LogoEngbers from "../../images/members/engbers.jpg";
import LogoHappySocks from "../../images/members/happy-socks.jpg";
import LogoHarperWilde from "../../images/members/harper-wilde.jpg";
import LogoBoss from "../../images/members/hugo-boss.jpg";
import LogoJako from "../../images/members/jako.jpg";
import LogoKatag from "../../images/members/katag-ag.jpg";
import LogoLaredoute from "../../images/members/la-redoute.jpg";
import LogoSinsay from "../../images/members/lpp_sinsay_1.jpg";
import LogoMaison from "../../images/members/maison-123.jpg";
import LogoMammut from "../../images/members/mammut.jpg";
import LogoMango from "../../images/members/mango.jpg";
import LogoMelon from "../../images/members/melon-fashion-group.jpg";
import LogoMontane from "../../images/members/montane.jpg";
import LogoPaul from "../../images/members/paul-andrew.jpg";
import LogoPlayshoes from "../../images/members/playshoes.jpg";
import LogoPockies from "../../images/members/pockies.jpg";
import LogoPresentTeam from "../../images/members/the-present-team.jpg";
import LogoPrimark from "../../images/members/primark.jpg";
import LogoRalphLauren from "../../images/members/ralph-lauren_1.jpg";
import LogoRovitex from "../../images/members/rovitex.jpg";
import LogoScandia from "../../images/members/scandia.jpg";
import LogoSeeHerWork from "../../images/members/see-her-work.jpg";
import LogoSkinEast from "../../images/members/ski-the-east_1.jpg";
import LogoSport2000 from "../../images/members/sport-2000.jpg";
import LogoTristyle from "../../images/members/tristyle-mode-gmbh.jpg";
import LogoVanGennip from "../../images/members/van-gennip-textiles.jpg";
import LogoVegotec from "../../images/members/vegotec-international.jpg";
import LogoWoom from "../../images/members/woom-gmbh.png";
import LogoEastman from "../../images/members/eastman-exports-global-clothing-pvt-ltd-.jpg";
import LogoEsstee from "../../images/members/esstee-exports.jpg";
import LogoPearlGlobal from "../../images/members/pearl-global.jpg";
import LogoRuby from "../../images/members/ruby-logo.jpg";
import LogoBateks from "../../images/members/bateks-logo-crop-u1294.jpg";
import LogoEdsa from "../../images/members/edsa_o.jpg";
import Logo3F from "../../images/members/3f-tekstil.jpg";
import LogoTexim from "../../images/members/texim.jpg";
import LogoViva from "../../images/members/viva-sports_n.jpg";
import LogoDelta from "../../images/members/delta-garment.jpg";
import LogoGulAhmed from "../../images/members/gul-ahmed.jpg";
import LogoGSsweaters from "../../images/members/gs-sweaters.jpg";
import LogoNeedle from "../../images/members/needle_n.jpg";
import LogoOlympic from "../../images/members/olympic-ashion-logo-2.jpg";
import LogoDJ from "../../images/members/guangzhou-weixin-germent-co.jpg";
import LogoSuxing from "../../images/members/suxing.jpg";
import LogoWonders from "../../images/members/wonders-1.jpg";
import LogoIsland from "../../images/members/island-cosmos.jpg";
import LogoReplica from "../../images/members/replica-malhas-confeccoes.jpg";
import LogoAurora from "../../images/members/aurora-investiments-global.jpg";
import LogoQTNP from "../../images/members/qtnp.jpg";
import LogoJR from "../../images/members/j-r-enterprises.jpg";
import LogoNaga from "../../images/members/naga-garments.jpg";
import LogoSweetGirl from "../../images/members/sweet-girl.jpg";
import LogoTalApparel from "../../images/members/tal-apparel.jpg";
import LogoTunisian from "../../images/members/tunisian-apparel.jpg";
import LogoDS from "../../images/members/ds-fashion.jpg";
import LogoConFi from "../../images/members/con-fi-fashion-service.png";
import LogoWondersF from "../../images/members/wonders.jpg";
import LogoShnidertex from "../../images/members/shnidertex.jpg";
import LogoWorldWA from "../../images/members/world-wide-apparel.jpg";
import LogoProStandard from "../../images/members/prostandard.jpg";
import LogoUBase from "../../images/members/ubase.jpg";
import LogoNemo from "../../images/members/nemo-sewing-factory.jpg";
import LogoMotives from "../../images/members/motives-international.jpg";

export const membersTexts = (t) => {
  return {
    tabList: [t("references.buyers"), t("references.suppliers")],
    tabContent: [
      {
        top: {
          title: {
            text: t("references.buyers"),
            colorClass: "colorDark",
            extraClass: "font40",
          },
          text: {
            text: t("references.buyersText"),
            colorClass: "colorDark",
            textType: "paragraph",
          },
          separator: true,
          backgroundGrey: "backgroundGrey",
        },
        content: [
          {
            img: { src: LogoCarrefour, alt: "Logo" },
            label: t("countries.hongKong"),
            href: "http://www.carrefour.com/",
          },
          {
            img: { src: LogoCentric, alt: "Logo" },
            label: t("countries.unitedStates"),
            href: "https://www.centricbrands.com/",
          },
          {
            img: { src: LogoChargeurs, alt: "Logo" },
            label: t("countries.germany"),
            href: "https://www.chargeurs-pcc.com/",
          },
          {
            img: { src: LogoChelmsford, alt: "Logo" },
            label: t("countries.unitedKingdom"),
            href: "https://www.chelmsfordsafety.co.uk/",
          },
          {
            img: { src: LogoChildren, alt: "Logo" },
            label: t("countries.france"),
            href: "http://www.groupecwf.com/",
          },
          {
            img: { src: LogoCrew, alt: "Logo" },
            label: t("countries.unitedKingdom"),
            href: "https://www.crewclothing.co.uk/",
          },
          {
            img: { src: LogoDoubleTwo, alt: "Logo" },
            label: t("countries.unitedKingdom"),
            href: "https://www.doubletwo.co.uk/",
          },
          {
            img: { src: LogoEngbers, alt: "Logo" },
            label: t("countries.germany"),
            href: "https://www.engbers.com/",
          },
          {
            img: { src: LogoHappySocks, alt: "Logo" },
            label: t("countries.sweden"),
            href: "https://www.happysocks.com/de/",
          },
          {
            img: { src: LogoHarperWilde, alt: "Logo" },
            label: t("countries.unitedStates"),
            href: "https://harperwilde.com/",
          },
          {
            img: { src: LogoBoss, alt: "Logo" },
            label: t("countries.germany"),
            href: "https://www.hugoboss.com/",
          },
          {
            img: { src: LogoJako, alt: "Logo" },
            label: t("countries.germany"),
            href: "https://www.jako.de/en/",
          },
          {
            img: { src: LogoKatag, alt: "Logo" },
            label: t("countries.germany"),
            href: "https://katag.net/",
          },
          {
            img: { src: LogoLaredoute, alt: "Logo" },
            label: t("countries.france"),
            href: "https://www.laredoute.com/de/",
          },
          {
            img: { src: LogoSinsay, alt: "Logo" },
            label: t("countries.poland"),
            href: "https://www.lppsa.com/en",
          },
          {
            img: { src: LogoMaison, alt: "Logo" },
            label: t("countries.france"),
            href: "https://www.maison123.com/",
          },
          {
            img: { src: LogoMammut, alt: "Logo" },
            label: t("countries.switzerland"),
            href: "https://www.mammut.com/de/de/",
          },
          {
            img: { src: LogoMango, alt: "Logo" },
            label: t("countries.spain"),
            href: "https://mango.com/",
          },
          {
            img: { src: LogoMelon, alt: "Logo" },
            label: t("countries.russia"),
            href: "https://www.melonfashion.com/",
          },
          {
            img: { src: LogoMontane, alt: "Logo" },
            label: t("countries.unitedKingdom"),
            href: "https://www.montane.com/",
          },
          {
            img: { src: LogoPaul, alt: "Logo" },
            label: t("countries.unitedKingdom"),
            href: "https://paul-andrew.com/",
          },
          {
            img: { src: LogoPlayshoes, alt: "Logo" },
            label: t("countries.germany"),
            href: "https://www.playshoes.de/",
          },
          {
            img: { src: LogoPockies, alt: "Logo" },
            label: t("countries.netherlands"),
            href: "https://www.pockies.com/",
          },
          {
            img: { src: LogoPresentTeam, alt: "Logo" },
            label: t("countries.greece"),
            href: "http://www.present-team.eu/",
          },
          {
            img: { src: LogoPrimark, alt: "Logo" },
            label: t("countries.unitedKingdom"),
            href: "https://www.primark.com/",
          },
          {
            img: { src: LogoRalphLauren, alt: "Logo" },
            label: t("countries.hongKong"),
            href: "https://www.ralphlauren.com/",
          },
          {
            img: { src: LogoRovitex, alt: "Logo" },
            label: t("countries.brazil"),
            href: "http://www.rovitex.com.br/",
          },
          {
            img: { src: LogoScandia, alt: "Logo" },
            label: t("countries.netherlands"),
            href: "https://www.scandiagear.com/",
          },
          {
            img: { src: LogoSeeHerWork, alt: "Logo" },
            label: t("countries.unitedStates"),
            href: "https://seeherwork.com/",
          },
          {
            img: { src: LogoSkinEast, alt: "Logo" },
            label: t("countries.unitedStates"),
            href: "https://www.skitheeast.net/",
          },
          {
            img: { src: LogoSport2000, alt: "Logo" },
            label: t("countries.germany"),
            href: "https://www.sport2000.de/",
          },
          {
            img: { src: LogoTristyle, alt: "Logo" },
            label: t("countries.germany"),
            href: "http://www.tristylegroup.com/",
          },
          {
            img: { src: LogoVanGennip, alt: "Logo" },
            label: t("countries.netherlands"),
            href: "https://www.vangenniptextiles.com/nl/",
          },
          {
            img: { src: LogoVegotec, alt: "Logo" },
            label: t("countries.belgium"),
            href: "http://www.vegotex.com/brands",
          },
          {
            img: { src: LogoWoom, alt: "Logo" },
            label: t("countries.austria"),
            href: "https://woombikes.com/en_US/",
          },
        ],
        cta: {
          text: t("menu.joinNow"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "buttonMenu"
        },
      },
      {
        top: {
          title: {
            text: t("references.suppliers"),
            colorClass: "colorDark",
            extraClass: "font40",
          },
          text: {
            text: t("references.suppliersText"),
            colorClass: "colorDark",
            textType: "paragraph",
          },
          separator: true,
          backgroundGrey: "backgroundGrey",
        },
        content: [
          {
            img: { src: LogoEastman, alt: "Logo" },
            label: t("countries.india"),
            href: "",
          },
          {
            img: { src: LogoEsstee, alt: "Logo" },
            label: t("countries.india"),
            href: "",
          },
          {
            img: { src: LogoPearlGlobal, alt: "Logo" },
            label: t("countries.india"),
            href: "http://www.pearlglobal.com/",
          },
          {
            img: { src: LogoRuby, alt: "Logo" },
            label: t("countries.india"),
            href: "https://linkedin.com/company/ruby-apparels---india",
          },
          {
            img: { src: LogoBateks, alt: "Logo" },
            label: t("countries.turkey"),
            href: "",
          },
          {
            img: { src: LogoEdsa, alt: "Logo" },
            label: t("countries.turkey"),
            href: "http://www.edsa.com.tr/tr/index.html",
          },
          {
            img: { src: Logo3F, alt: "Logo" },
            label: t("countries.turkey"),
            href: "http://www.3ftextile.com/",
          },
          {
            img: { src: LogoTexim, alt: "Logo" },
            label: t("countries.turkey"),
            href: "https://www.texim.com.tr/",
          },
          {
            img: { src: LogoViva, alt: "Logo" },
            label: t("countries.pakistan"),
            href: "https://pro.foursource.com/vivosportswears",
          },
          {
            img: { src: LogoDelta, alt: "Logo" },
            label: t("countries.pakistan"),
            href: "https://www.deltagarments.com.pk/",
          },
          {
            img: { src: LogoGulAhmed, alt: "Logo" },
            label: t("countries.pakistan"),
            href: "http://gulahmed.com/",
          },
          {
            img: { src: LogoGSsweaters, alt: "Logo" },
            label: t("countries.bangladesh"),
            href: "https://www.gssweaters.com/",
          },
          {
            img: { src: LogoNeedle, alt: "Logo" },
            label: t("countries.bangladesh"),
            href: "https://www.needledropltd.com/",
          },
          {
            img: { src: LogoOlympic, alt: "Logo" },
            label: t("countries.bangladesh"),
            href: "https://public.foursource.com/profile/olympic-fashion-limited-db35b1f3d006b037c6acf898da036d58",
          },
          {
            img: { src: LogoDJ, alt: "Logo" },
            label: t("countries.china"),
            href: "http://www.dnjgarment.com/",
          },
          {
            img: { src: LogoSuxing, alt: "Logo" },
            label: t("countries.china"),
            href: "https://public.foursource.com/profile/purple-changzhou-suxing-century--1905aedab9bf2477edc068a355bba31a",
          },
          {
            img: { src: LogoWonders, alt: "Logo" },
            label: t("countries.china"),
            href: "https://pro.foursource.com/xiamen-wonders-sport-co-ltd",
          },
          {
            img: { src: LogoIsland, alt: "Logo" },
            label: t("countries.portugal"),
            href: "http://www.islandcosmos.com/",
          },
          {
            img: { src: LogoReplica, alt: "Logo" },
            label: t("countries.portugal"),
            href: "http://www.replica.com.pt/pt/",
          },
          {
            img: { src: LogoAurora, alt: "Logo" },
            label: t("countries.vietnam"),
            href: "https://public.foursource.com/profile/aurora-investments-global-limited-43fa7f58b7eac7ac872209342e62e8f1",
          },
          {
            img: { src: LogoQTNP, alt: "Logo" },
            label: t("countries.vietnam"),
            href: "https://public.foursource.com/profile/qtnp-garment-jsc-2-9acf1b76f369030270de8f98f84d6707",
          },
          {
            img: { src: LogoJR, alt: "Logo" },
            label: t("countries.romania"),
            href: "https://public.foursource.com/profile/jr-enterprises-srl--ae5e3ce40e0404a45ecacaaf05e5f735",
          },
          {
            img: { src: LogoNaga, alt: "Logo" },
            label: t("countries.egypt"),
            href: "https://public.foursource.com/profile/naga-garments-co-2-0b96d81f0494fde5428c7aea243c9157",
          },
          {
            img: { src: LogoSweetGirl, alt: "Logo" },
            label: t("countries.egypt"),
            href: "https://public.foursource.com/profile/sweet-gril-1ecfb463472ec9115b10c292ef8bc986",
          },
          {
            img: { src: LogoTalApparel, alt: "Logo" },
            label: t("countries.hongKong"),
            href: "https://pro.foursource.com/besfit-company-limited",
          },
          {
            img: { src: LogoTunisian, alt: "Logo" },
            label: t("countries.tunisia"),
            href: "",
          },
          {
            img: { src: LogoDS, alt: "Logo" },
            label: t("countries.bulgaria"),
            href: "",
          },
          {
            img: { src: LogoConFi, alt: "Logo" },
            label: t("countries.italy"),
            href: "",
          },
          {
            img: { src: LogoWondersF, alt: "Logo" },
            label: t("countries.poland"),
            href: "https://pro.foursource.com/wonders-fashion-anna-wesoowska",
          },
          {
            img: { src: LogoShnidertex, alt: "Logo" },
            label: t("countries.indonesia"),
            href: "https://public.foursource.com/profile/shnidertex-7cf65e31e39837a301b0173e2a0701aa",
          },
          {
            img: { src: LogoWorldWA, alt: "Logo" },
            label: t("countries.philippines"),
            href: "",
          },
          {
            img: { src: LogoProStandard, alt: "Logo" },
            label: t("countries.taiwan"),
            href: "http://www.prostandard.com.tw/",
          },
          {
            img: { src: LogoUBase, alt: "Logo" },
            label: t("countries.southKorea"),
            href: "http://www.ubaseinternational.com/",
          },
          {
            img: { src: LogoNemo, alt: "Logo" },
            label: t("countries.latvia"),
            href: "",
          },
          {
            img: { src: LogoMotives, alt: "Logo" },
            label: t("countries.unitedStates"),
            href: "https://motivesinternational.io/",
          },
        ],
        cta: {
          text: t("menu.joinNow"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "buttonMenu"
        },
      },
    ],
  };
};
